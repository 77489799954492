const trayCheckSummaryReportObj = {
  title: "Tray Check Summary Report",
  isEnterprise: "",
  isStandard: false,
  ignoreDateRange: false,
  collections: [
    "tray",
    "user",
    "product",
    "stockLocation",
    "dispensingOrder",
    "facility", // custom extended
  ],
  fields: [
    {
      collection: "stockLocation",
      dataType: "String",
      name: "Device Name",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrder",
      dataType: "String",
      name: "Batch Id",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "facility", // custom extended
      dataType: "String",
      name: "Facility Name",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "facility", // custom extended
      dataType: "String",
      name: "Facility Id",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrder",
      dataType: "String",
      name: "Dispensing Order Id",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "String",
      name: "Location Id",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrder",
      dataType: "Number",
      name: "Total # of Trays",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "String",
      name: "status",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "Date",
      name: "Tray Check Date/Time",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "user",
      dataType: "String",
      name: "Checked by",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "tray",
      dataType: "String",
      name: "Tray #",
      query: [],
      sum: false,
      average: false,
    },
  ],
  functionFields: [],
  sites: [],
  groupedField: "No grouping",
  showCustomReport: false,
  customizedReport: "",
};

export default trayCheckSummaryReportObj;
