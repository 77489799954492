import React, { useState, useEffect, useContext, Fragment } from "react";
import ConfigService from "../../services/ConfigService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Report2Header from "./Report2Header/Report2Header";
import Spacer from "../../components/Spacer";
import ErrorBanner from "../../components/ErrorBanner";
import SearchBar from "../../components/SearchBar";
import TabBar from "../../components/TabBar";
import RoleCheckService from "../../services/RoleCheckService";
import CreateButton from "../../components/CreateButton";
import ReportTile from "../../components/ReportTile";
import Button from "../../components/Button";
import ModalBackdrop from "../../components/Modal";
import ReportService from "../../services/ReportService";
import { deepCopy } from "../../functions/utilities";
import ReportViewer from "./ReportViewer";
import FillList from "./FillList/FillList";
import RefillReport from "./CustomizedReports/RefillReportForHealthDirect/RefillReport";
import ReportViewerEV54CanisterManagement from "./ReportViewerEV54CanisterManagement";
import TrayCheckSummaryReportViewer from "./CustomizedReports/TrayCheckSummary/TrayCheckSummaryReportViewer";
import CreateLabel from "../LabelEditor/CreateLabel";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./Reports2.module.css";

const Reports = ({
  user,
  navToggle,
  returnURL,
  reportName,
  dataFromDashboard,
}) => {
  // const independentReports = ["Replenishment"];
  const customizedReports = [
    "EV54 Canister Management",
    "Summary Bag Report",
    "Tray Check Summary",
  ];

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const { t } = useTranslation("reports");

  const navigate = useNavigate();

  const actionListItems = [
    {
      type: "modify",
      description: t("action0"),
    },
    {
      type: "copy",
      description: t("action2"),
    },
    {
      type: "delete",
      description: t("action1"),
    },
    // {
    //   type: "standardize",
    //   description: "standardize",
    // },
  ];

  const tabs = [
    {
      index: 0,
      text: "Reports",
      icon: null,
    },
    {
      index: 1,
      text: "Labels",
      icon: null,
    },
  ];

  const handleSelectedTab = (index) => {
    if (index === 0) {
      setSelectedTab({ ...tabs[0] });
    } else if (index === 1) {
      setSelectedTab({ ...tabs[1] });
    }
    setSelectedTab(tabs[index]);
  };

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [userAccessibleSites, setUserAccessibleSites] = useState([]);
  const [subscribeToReport, setSubscribeToReport] = useState(false);

  const emptyReportObj = {
    title: "",
    isEnterprise: false,
    isStandard: false,
    ignoreDateRange: false,
    collections: [],
    fields: [],
    functionFields: [],
    functionLookups: {},
    sites: [],
    groupedField: "No grouping",
    showCustomReport: false,
    customizedReport: "",
  };

  const [error, setError] = useState("");
  const [filterText, setFilterText] = useState("");
  const [currentAction, setCurrentAction] = useState(0);
  const [reports, setReports] = useState([]);
  const [reportObj, setReportObj] = useState(emptyReportObj);
  const [reportToRun, setReportToRun] = useState(null);

  const productionReportTitle = "Production";

  const [originalReportObj, setOriginalReportObj] = useState("");
  const [fromHeaderPage, setFromHeaderPage] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [updateReports, setUpdateReports] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  const [showDeleteReportWarning, setShowDeleteReportWarning] = useState(false);
  const [reportObjSelectedForDeletion, setReportObjSelectedForDeletion] =
    useState(null);

  const [showTabBar, setShowTabBar] = useState(true);

  const showableMobileReports = ["Replenishment", "Production"];

  const [collectionFieldDictionary, setCollectionFieldDictionary] = useState(
    {}
  );

  const TabBarComponent = (
    <TabBar
      tabs={tabs}
      onSelectedTab={handleSelectedTab}
      fontSize={"14px"}
      dynamicActiveIndex={selectedTab.index}
    />
  );

  /************************************************************ */
  useEffect(() => {
    ConfigService.reportConcepts(user).then((reportConcepts) => {
      let tempCollectionFieldDictionary = {};
      if (reportConcepts.length) {
        reportConcepts.forEach((concept) => {
          tempCollectionFieldDictionary[concept.schema] = {};
          for (const [key, value] of Object.entries(concept)) {
            if (key !== "associations" && key !== "schema" && key !== "title") {
              tempCollectionFieldDictionary[concept.schema][key] = value;
            }
          }
        });
      }
      setCollectionFieldDictionary(tempCollectionFieldDictionary);
    });
  }, [user]);
  /************************************************************ */

  useEffect(() => {
    if (subscribeToReport) {
      handleCreate();
    }
  }, [subscribeToReport]);

  const handleSetCurrentAction = (actionNum) => {
    setCurrentAction(actionNum);
  };

  let ViewerComponent; // used for report to run

  /*********************  customized reports with own viewer ************************/
  if (
    !smScreen &&
    reportToRun?.customizedReport &&
    reportToRun.customizedReport === "EV54 Canister Management"
  ) {
    ViewerComponent = (
      <ReportViewerEV54CanisterManagement
        isPreview={false}
        reportObj={reportToRun}
        user={user}
        onClose={() => {
          setReportToRun(null);
        }}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
    /**/
  } else if (
    !smScreen &&
    reportToRun?.customizedReport &&
    reportToRun.customizedReport === "Tray Check Summary"
  ) {
    ViewerComponent = (
      <TrayCheckSummaryReportViewer
        isPreview={false}
        reportObj={reportToRun}
        user={user}
        onClose={() => {
          setReportToRun(null);
        }}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
    /**********************************************************************************/
    /*********************  independent report with own viewer ************************/
  } else if (
    !smScreen &&
    reportToRun?.customizedReport &&
    reportToRun.customizedReport === "Refill Report"
  ) {
    ViewerComponent = (
      <RefillReport
        user={user}
        onHandleSetCurrentAction={handleSetCurrentAction}
        onClose={() => {
          setReportToRun(null);
        }}
      />
    );
  } else if (
    reportToRun?.customizedReport &&
    reportToRun.customizedReport === "Replenishment"
  ) {
    ViewerComponent = (
      <FillList
        user={user}
        onHandleSetCurrentAction={handleSetCurrentAction}
        onClose={() => {
          setReportToRun(null);
        }}
      />
    );
    /**********************************************************************************/
  } else if (reportToRun?.title) {
    ViewerComponent = (
      <ReportViewer
        isPreview={false}
        reportObj={reportToRun}
        user={user}
        onClose={() => {
          setReportToRun(null);
        }}
        returnURL={returnURL}
        customizedReports={customizedReports}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  }

  // Get the pharmacy sites that this user has access rights to
  useEffect(() => {
    RoleCheckService.getAccessibleSites(user)
      .then((sites) => {
        sites.sort((a, b) => {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
          }
          return 0;
        });
        setUserAccessibleSites(sites);
      })
      .catch((error) => {
        // setError(error);
        console.log("error");
      });
  }, [user]);

  useEffect(() => {
    setReportObj(originalReportObj || emptyReportObj);
  }, [originalReportObj]);

  useEffect(() => {
    if (currentAction === 0) {
      handleReportObj(emptyReportObj);
      setOriginalReportObj(emptyReportObj);
    }
  }, [currentAction]);

  // Reset to main Report view when menu item is clicked
  useEffect(() => {
    setReportToRun(null);
    setCurrentAction(0);
  }, [navToggle]);

  // Reset view to reports selection screen if mobile view and non-showable mobile report is active
  useEffect(() => {
    if (
      smScreen &&
      reportToRun &&
      reportToRun?.title &&
      !showableMobileReports.includes(reportToRun.title)
    ) {
      setReportToRun(null);
    }
  }, [smScreen, reportToRun]);

  // If mobile view, show mobile header (navbar hamburger, alarm bell, user profile photo)
  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen, handleShowSmScreenHeader]);

  // GET THE CURRENT REPORTS
  useEffect(() => {
    if (!reports.length || updateReports)
      ReportService.list2(user)
        .then((results) => {
          setReports(results);
        })
        .catch((error) => {
          setError(error);
        });

    setUpdateReports(false);
  }, [user, updateReports]);

  const handleShowTabBar = (boolVal) => {
    setShowTabBar(boolVal);
  };

  const handleReportObj = (obj) => {
    setReportObj(obj);
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleEditMode = (bool) => {
    setEditMode(bool);
  };

  const handleCreate = () => {
    setOriginalReportObj("");
    setCurrentAction(1);
    handleIsNew();
  };

  const handleCreated = () => {
    setUpdateReports(true);
    setCurrentAction(0);
    setSubscribeToReport(false);
  };

  const handleUpdated = () => {
    setUpdateReports(true);
    setCurrentAction(0);
    handleEditMode(false);
  };

  const getActionMenu = (reportCreatorId, customizedReport) => {
    let listItems = [...actionListItems];
    // Allow a report to be modified only if it is not a customized report and if the user is the same as the report owner.
    // Allow a report to be deleted only if the user is the same as the report owner.
    if (
      !RoleCheckService.editCustomReports(user) &&
      !RoleCheckService.removeCustomReports(user) &&
      reportCreatorId !== user._id
    ) {
      let index = listItems.findIndex((o) => o.type === "modify");
      listItems.splice(index, 1);
      index = listItems.findIndex((o) => o.type === "delete");
      listItems.splice(index, 1);
    } else if (customizedReport) {
      // let index = listItems.findIndex((o) => o.type === "modify");
      // listItems.splice(index, 1);
    }
    return listItems;
  };

  const getStandardActionMenu = (reportObj) => {
    let listItems = [];

    if (RoleCheckService.canCreateReports(user)) {
      listItems = [...actionListItems];
      // remove modify & delete
      let index = listItems.findIndex((o) => o.type === "modify");
      listItems.splice(index, 1);
      index = listItems.findIndex((o) => o.type === "delete");
      listItems.splice(index, 1);
    }
    return listItems;
  };

  const handleAction = async (action, reportObj) => {
    let index = -1;

    for (let i = 0; i < reports.length; i++) {
      if (reportObj === reports[i].reportObject) {
        index = i;
      }
    }

    const startingReportObj = fromHeaderPage
      ? deepCopy(reports[index].reportObject)
      : deepCopy(reports[index].reportObject);

    if (!startingReportObj.hasOwnProperty("functionLookups")) {
      startingReportObj.functionLookups = {};
    }

    switch (action.type) {
      case "modify":
        if (startingReportObj) {
          setEditMode(true);
          setIsNew(false);
          setCurrentAction(1);
          setOriginalReportObj(startingReportObj);
        }
        break;
      case "delete":
        await handleDeleteReportWarning(reportObj);
        break;
      case "copy":
        let reportObjToCopy = { ...reportObj };
        reportObjToCopy.title = `${reportObj.title}-copy`;
        reportObjToCopy.isStandard = false;
        await handleCopy(reportObjToCopy);
        break;
      // case "standardize":
      //   await handleStandardize(reportObj);
      //   break;
      default:
        break;
    }
  };

  const handleIsNew = () => {
    setIsNew(true);
  };

  const handleIsNotNew = () => {
    setIsNew(false);
  };

  const checkIfReportNameExists = (name) => {
    let nameFound = false;

    for (let i = 0; i < reports.length; i++) {
      if (reports[i].name === name && reports[i].account === user.account._id) {
        nameFound = true;
        break;
      }
    }
    return nameFound;
  };

  const handleSaveData = async () => {
    if (isNew) {
      ReportService.create2(user, reportObj)
        .then((newReport) => {
          // console.log("newReport: ", newReport);
          handleCreated();
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      let reportToUpdate = reports.find(
        (o) =>
          o.name === originalReportObj.title && o.account === user.account._id
      );

      let modifiedReportToUpdate = {
        ...reportToUpdate,
        reportObject: reportObj,
        name: reportObj.title,
      };

      if (
        modifiedReportToUpdate.reportObject?.customizedReport &&
        modifiedReportToUpdate.reportObject?.customizedReport ===
          "Summary Bag Report"
      ) {
        modifiedReportToUpdate.reportObject.collections = [];
        modifiedReportToUpdate.reportObject.fields = [];
      }

      ReportService.update(user, modifiedReportToUpdate)
        .then((updatedReport) => {
          handleUpdated();
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const handleShowPreview = () => {
    const query = {
      startDate: new Date(),
      stopDate: new Date(),
      isPreview: true,
      reportObj: reportObj,
    };

    ReportService.run2(user, query)
      .then(() => {
        setIsPreview(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const previewButton = (
    <Button
      labelName="Preview"
      minWidth={"123px"}
      isPrimary={true}
      onClick={handleShowPreview}
      isDisabled={
        reportObj.title &&
        (reportObj.collections.length > 0 || reportObj?.customizedReport) &&
        (reportObj.fields.length > 0 || reportObj?.customizedReport)
          ? false
          : true
      }
    />
  );

  const handleStandardize = async (reportObjToStandardize) => {
    // console.log("report standardized");
    // console.log("reportObj: ", reportObjToStandardize);
  };

  const handleCopy = async (reportObjToCopy) => {
    await ReportService.create2(user, reportObjToCopy)
      .then((newReport) => {
        setUpdateReports(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleDeleteReportWarning = async (reportObj) => {
    setReportObjSelectedForDeletion(reportObj);
    setShowDeleteReportWarning(true);
  };

  const handleCancelDeleteReport = () => {
    setShowDeleteReportWarning(false);
    setReportObjSelectedForDeletion(null);
  };

  const handleDeleteReport = async () => {
    if (reportObjSelectedForDeletion) {
      let reportToDelete = reports.find(
        (o) => o.name === reportObjSelectedForDeletion.title
      );

      await ReportService.delete(user, reportToDelete)
        .then((reportDoc) => {
          setUpdateReports(true);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setShowDeleteReportWarning(false);
          setReportObjSelectedForDeletion(null);
        });
    }
  };

  const handleFromHeaderPage = () => {
    setFromHeaderPage(true);
    setSubscribeToReport(false);
  };

  const handleNotFromHeaderPage = () => {
    setFromHeaderPage(false);
  };

  let ReportViewerForPreview;

  /*********************  customized report with own viewer ************************/
  if (
    !smScreen &&
    reportObj &&
    reportObj?.customizedReport &&
    reportObj.customizedReport === "EV54 Canister Management"
  ) {
    ReportViewerForPreview = (
      <ReportViewerEV54CanisterManagement
        isPreview={true}
        reportObj={reportObj}
        user={user}
        onClose={() => {
          setIsPreview(false);
        }}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
    /**********************************************************************************/
  } else {
    ReportViewerForPreview = (
      <ReportViewer
        isPreview={true}
        reportObj={reportObj}
        user={user}
        onClose={() => {
          setIsPreview(false);
        }}
        customizedReports={customizedReports}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  }

  let productionReportObj;
  if (
    dataFromDashboard &&
    dataFromDashboard?.returnURL &&
    dataFromDashboard?.reportName
  ) {
    productionReportObj = reports.find(
      (element) => element?.name === reportName
    );
  }

  // Render
  if (productionReportObj && productionReportObj?.reportObject) {
    return (
      <ReportViewer
        isPreview={false}
        reportObj={productionReportObj.reportObject}
        user={user}
        onClose={() => {
          setReportToRun(null);
          navigate("/dashboard", {
            state: {
              dashboardReturnState: {
                returnView: dataFromDashboard?.returnView,
                selectedSite: dataFromDashboard?.selectedSite,
              },
            },
          });
        }}
        returnURL={returnURL}
        customizedReports={customizedReports}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  } else if (reportToRun) {
    return ViewerComponent; /************/
  } else if (currentAction === 1) {
    return (
      <Report2Header
        reportObj={reportObj}
        subscribeToReport={subscribeToReport}
        handleReportObj={handleReportObj}
        originalReportObj={originalReportObj}
        user={user}
        onClose={() => {
          setCurrentAction(0);
        }}
        onSaveData={handleSaveData}
        handleFromHeaderPage={handleFromHeaderPage}
        handleNotFromHeaderPage={handleNotFromHeaderPage}
        onIsNotNew={handleIsNotNew}
        editMode={editMode}
        onEditMode={handleEditMode}
        checkIfReportNameExists={checkIfReportNameExists}
        previewButton={previewButton}
        ReportViewerForPreview={ReportViewerForPreview}
        isPreview={isPreview}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
    {
      /*************************** independent report *****************************/
    }
  } else if (currentAction === 2) {
    return (
      <FillList
        user={user}
        onHandleSetCurrentAction={handleSetCurrentAction}
        onClose={() => {
          setReportToRun(null);
        }}
      />
    );
    {
      /****************************************************************************/
    }
  } else if (smScreen || selectedTab.index === 0) {
    return (
      <Fragment>
        {showDeleteReportWarning && (
          <ModalBackdrop
            width="100%"
            height="100%"
            top="0"
            left="0"
            padding="0"
            showButton={false}
            backgroundColor="#98a9bc4d"
            borderRadius="0"
          >
            <div className={styles.Reports__deleteWarningModal}>
              <p>Do you really want to delete this report?</p>
              <div className={styles.Reports__ModalButtonsGroup}>
                <Button
                  labelName="Cancel"
                  minWidth={"123px"}
                  isPrimary={false}
                  onClick={handleCancelDeleteReport}
                />
                <Spacer space={20} unit={"px"} />
                <Button
                  labelName="Delete"
                  minWidth={"123px"}
                  isPrimary={true}
                  onClick={handleDeleteReport}
                />
              </div>
            </div>
          </ModalBackdrop>
        )}
        <div className={styles.Reports__view}>
          {!smScreen && (
            <div className={styles.Reports__pageHeaderContainer}>
              {TabBarComponent}
            </div>
          )}

          {smScreen && (
            <div className={styles.Reports__SearchBarContainer}>
              <SearchBar
                placeholder="Search"
                onSearch={handleSearch}
                focus={true}
              />
            </div>
          )}
          {error && (
            <>
              <Spacer space={70} unit={"px"} />
              <ErrorBanner
                message={error}
                onClose={() => {
                  setError(null);
                }}
              />
              <Spacer space={10} unit={"px"} />
            </>
          )}
          {!smScreen && (
            <div className={styles.Reports__header}>
              <Spacer space={26} unit={"px"} />
              <div
                className={styles.Reports__row}
                style={{ justifyContent: "space-between" }}
              >
                <SearchBar
                  placeholder={t("searchPlaceholder")}
                  onSearch={handleSearch}
                  focus={true}
                />
                <div className={styles.Reports__reportOptions}>
                  {RoleCheckService.canCreateReports(user) && (
                    <CreateButton
                      onClick={() => setSubscribeToReport(true)}
                      isEnabled={true}
                      labelName="Subscribe to Report"
                    />
                  )}
                  {RoleCheckService.canCreateReports(user) && (
                    <CreateButton
                      onClick={handleCreate}
                      isEnabled={true}
                      labelName={t("createButtonLabel")}
                    />
                  )}
                </div>
              </div>
              <Spacer space={50} unit={"px"} />
            </div>
          )}

          <main className={styles.Reports__mainBody}>
            <div className={styles.Reports__row}>
              <div className={styles.Reports__title}>{t("templateTitle")}</div>
            </div>
            <Spacer space={smScreen ? 16 : 30} unit={"px"} />
            {/************************************
                       Standard Reports 
            **************************************/}
            <div
              className={`${styles.Reports__container} ${styles["Reports__container--standard"]}`}
            >
              {reports
                .filter((r) => {
                  return (
                    r?.reportObject?.isStandard &&
                    (!smScreen ||
                      r.name === productionReportTitle ||
                      !smScreen ||
                      (r.reportObject?.customizedReport &&
                        r.reportObject.customizedReport === "Replenishment")) &&
                    (filterText
                      ? r.name.toUpperCase().includes(filterText)
                      : true)
                  );
                })
                .map((reportObjFromDatabase) => {
                  return (
                    <ReportTile
                      key={reportObjFromDatabase._id}
                      imageName={"barChart"}
                      imageFillColor={"#089BAB"}
                      title={reportObjFromDatabase.reportObject.title}
                      isTemplate={false}
                      actions={getStandardActionMenu(
                        reportObjFromDatabase.reportObject
                      )}
                      onAction={(action) => {
                        handleAction(
                          action,
                          reportObjFromDatabase.reportObject
                        );
                      }}
                      onRunReport={() => {
                        reportObjFromDatabase.reportObject?.customizedReport &&
                        reportObjFromDatabase.reportObject.customizedReport ===
                          "Replenishment"
                          ? setCurrentAction(2)
                          : setReportToRun(reportObjFromDatabase.reportObject);
                      }}
                    />
                  );
                })}
            </div>
            {!smScreen && (
              <>
                <Spacer space={30} unit={"px"} />
                <div className={styles.Reports__row}>
                  <div className={styles.Reports__title}>
                    {t("customReportTitle")}
                  </div>
                </div>
                <Spacer space={30} unit={"px"} />
                {/************************************
                       Custom Reports 
            **************************************/}
                <div
                  className={`${styles.Reports__container} ${styles["Reports__container--custom"]}`}
                >
                  <ReportTile
                    imageName={"barChart"}
                    imageFillColor={"#A7BBCF"}
                    title={"Create New Template"}
                    isTemplate={true}
                    onCreateTemplate={handleCreate}
                  />
                  {/* show all standard reports and only custom reports created by the user */}
                  {reports
                    .filter((r) => {
                      let showCustomReport = false;
                      let matchedUserSites = [];
                      if (!r?.reportObject?.isEnterprise) {
                        matchedUserSites = r.reportObject?.sites.filter(
                          (rValue) => {
                            return userAccessibleSites.filter((sitesValue) => {
                              return sitesValue._id === rValue._id;
                            });
                          }
                        );
                      }

                      if (
                        r?.user === user._id ||
                        (r?.reportObject?.showCustomReport &&
                          r?.reportObject?.isEnterprise &&
                          r?.account === user.account._id)
                      ) {
                        showCustomReport = true;
                      } else if (
                        r?.reportObject?.showCustomReport &&
                        r?.account === user.account._id &&
                        matchedUserSites.length
                      ) {
                        showCustomReport = true;
                      }
                      return (
                        r.account === user.account._id &&
                        !r?.reportObject?.isStandard &&
                        (filterText
                          ? r.name.toUpperCase().includes(filterText) &&
                            showCustomReport
                          : showCustomReport)
                      );
                    })
                    .map((reportObjFromDatabase, index) => {
                      return (
                        <ReportTile
                          key={index}
                          imageName={"barChart"}
                          imageFillColor={"#089BAB"}
                          title={reportObjFromDatabase.reportObject.title}
                          isTemplate={false}
                          actions={getActionMenu(
                            reportObjFromDatabase.user,
                            reportObjFromDatabase.reportObject?.customizedReport
                          )}
                          onAction={(action) => {
                            handleAction(
                              action,
                              reportObjFromDatabase.reportObject
                            );
                          }}
                          onRunReport={() => {
                            reportObjFromDatabase?.customizedReport &&
                            reportObjFromDatabase.customizedReport ===
                              "Replenishment"
                              ? setCurrentAction(2)
                              : setReportToRun(
                                  reportObjFromDatabase.reportObject
                                );
                          }}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </main>
        </div>
      </Fragment>
    );
  } else {
    return (
      <div className={styles.Reports__labelEditorPageContainer}>
        {showTabBar && TabBarComponent}
        {
          <CreateLabel
            user={user}
            onSelectedTab={handleSelectedTab}
            onShowTabBar={handleShowTabBar}
          />
        }
      </div>
    );
  }
};

export default Reports;
